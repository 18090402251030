import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import headerImg2 from "../assets/img/logo-combined-white.png";

import background4 from "../assets/img/background3.jpg";

import TrackVisibility from "react-on-screen";

import { FaCheckCircle, FaHandHoldingHeart } from "react-icons/fa";
import Snowfall from "react-snowfall"; // Import the Snowfall component

export const Banner_Mobile = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(100 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["उत्तमस्य जगतः कृते", "For Better World"];
  const period = 500;
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setBackgroundIndex((prevIndex) => (prevIndex + 1) % 4);
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, []);

  const backgrounds = [background4];

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleRegisterUnstop = () => {
    window.open(
      "https://unstop.com/college-fests/sankalan25-civil-conclave-indian-institute-of-technology-iit-kanpur-294513?lb=n6aLdjXi&utm_medium=Share&utm_source=WhatsApp",
      "_blank"
    );
  };

  return (
    <section
      className="banner"
      id="home"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${background4})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        transition: "background-image 1s ease-in-out",
      }}
    >
      <Snowfall
        snowflakeCount={100}
        style={{
          zIndex: 2,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />

      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h1 style={{ fontFamily: "Times New Roman" }}>
                    {`Sankalan - `}
                    <span
                      className="txt-rotate"
                      dataPeriod="1000"
                      data-rotate='[ "उत्तमस्य ", "जगतः", "कृते" ]'
                    >
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>

                  <span className="tagline-date" style={{ marginTop: "10px" }}>
                    <h4>
                      <b>27th & 28 January, 2024</b>
                    </h4>
                  </span>
                  <div className="para-logo">
                    <p
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "20px",
                        textAlign: "justify",
                      }}
                    >
                      Established more than five decades ago, the Society of
                      Civil Engineers (SoCE) is the oldest departmental society
                      at the Indian Institute of Technology Kanpur. We are
                      driven by a shared enthusiasm and a commitment to promote
                      a sense of community, academic and professional
                      development, and a passion for civil engineering. We are
                      envisioned as a collaboration harnessing the substantial
                      resources, experience, and technical knowledge of
                      professors and alumni, all of which can greatly benefit
                      students within our department by providing them with a
                      comprehensive understanding of career opportunities and
                      the scope of civil engineering.
                    </p>
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img
              src={headerImg2}
              alt="Header Img"
              width="800px"
              height="800px"
            />
            <button
              className="register-btn-unstop mx-auto d-block text-dark mt-n6 shining-effect"
              onClick={handleRegisterUnstop}
            >
              <strong>Register Here</strong>
            </button>
          </Col>
        </Row>
      </Container>
      <div className="links-container">
        {/* Register Here link */}
        <a href="/accommodation" className="register-tag">
          <FaCheckCircle size={20} style={{ marginRight: "5px" }} />
          <span className="tagline">Register Here</span>
        </a>

        {/* Alum Support link */}
        <a href="/contribution" className="alum-support-tag">
          <FaHandHoldingHeart size={20} style={{ marginRight: "5px" }} />
          <span className="tagline">Alum Support</span>
        </a>
      </div>
    </section>
  );
};

export default Banner_Mobile;
