import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "./components/Banner";
import { Analytics } from "firebase/analytics";

import Footer from "./components/Footer/footer";
import { CampusAmbassador } from "./components/campusambassador";

import SideNavBar from "./components/SideNavBar/SideNavBar";

import MobileNavbar from "./components/MobileNavbar/MobileNavBar";
import { AboutSankalan } from "./components/aboutsankalan";

import ArticleComponent from "./components/Events/EventsCard";
import FeatureCards from "./components/Events/EventsCard";

import YourComponent from "./components/Test";
import About from "./About_Sankalan";

import Banner_Mobile from "./components/Banner_Mobile";
import ScrollingTicker from "./components/Scrolling_Ticker";
import { getAnalytics, logEvent } from "firebase/analytics";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAomn2QDD_nBRlsX5z1aqUA2uQs0t5-HOw",
  authDomain: "sankalan-23.firebaseapp.com",
  projectId: "sankalan-23",
  storageBucket: "sankalan-23.appspot.com",
  messagingSenderId: "578696265839",
  appId: "1:578696265839:web:da109cfc7cf5b47daa8d9a",
  measurementId: "G-NTHFK0S6KB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [isExpanded, setExpandedState] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "notification_received");
  }, []);

  const isMobileView = windowWidth <= 768;

  return (
    <div className="App">
      {isMobileView ? (
        <MobileNavbar />
      ) : (
        <SideNavBar
          isExpanded={isExpanded}
          setExpandedState={setExpandedState}
        />
      )}
      {isMobileView ? (
        <div>
          {/* Render mobile-specific components */}
          <ScrollingTicker />
          <Banner_Mobile />
          <AboutSankalan />
          <FeatureCards />
          {/* <Projects /> */}
          <CampusAmbassador />

          <Footer />
        </div>
      ) : (
        <div style={{ marginLeft: isExpanded ? "300px" : "85px" }}>
          {/* Render desktop-specific components */}

          <ScrollingTicker />
          <Banner />
          {/* <About /> */}
          <AboutSankalan />
          {/* <YourComponent /> */}
          <FeatureCards />

          <CampusAmbassador />

          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
