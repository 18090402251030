import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  ps_link,
  reg_link,
  last_date,
}) => {
  useEffect(() => {
    const projImgbx = document.querySelector(".proj-imgbx");

    if (projImgbx) {
      projImgbx.addEventListener("mouseenter", handleHover);
      projImgbx.addEventListener("mouseleave", handleHover);
    }

    return () => {
      if (projImgbx) {
        projImgbx.removeEventListener("mouseenter", handleHover);
        projImgbx.removeEventListener("mouseleave", handleHover);
      }
    };
  }, []);

  const handleHover = () => {
    const projImgbx = document.querySelector(".proj-imgbx");
    if (projImgbx) {
      projImgbx.classList.toggle("active");
    }
  };

  return (
    <Col size={12} sm={6} md={6} lg={3} style={{ marginTop: "2vh" }}>
      <div className="proj-imgbx">
        <img
          src={imgUrl}
          alt={title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "1vh",
            marginTop: "-2vh",
          }}
        />
        <div className="proj-txtx" style={{ width: "100%" }}>
          <h4 className="ps-title" style={{ fontSize: "20px" }}>
            {title}
          </h4>
          <span>
            <p
              className={`short_brief ${
                window.innerWidth <= 767 ? "small-font" : ""
              }`}
              style={{
                textAlign: "justify",
                fontSize: "13px",
                marginleft: "1vh",
                padding: "2vh",
                fontStyle: "italic",
              }}
            >
              {description}
            </p>
            <p
              className={`small-font`}
              style={{
                textAlign: "justify",
                fontSize: "14px",
                padding: "2vh",
                fontStyle: "italic",
                marginTop: "-2vh",
              }}
            >
              <span style={{ fontWeight: 300 }}>Deadline to Register</span>:{" "}
              {last_date}
            </p>
          </span>

          <div className="btn-container">
            <a
              href={ps_link}
              className="btn btn-primary mr-2"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: "1vh",
              }}
            >
              Learn More
            </a>
            <a
              href={reg_link}
              className="btn btn-success"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};
