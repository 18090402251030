import React, { useState } from "react";
import "./MobileNavbar.css";
import {
  FaHome,
  FaUsers,
  FaBell,
  FaCalendarAlt,
  FaCalendar,
  FaEnvelope,
  FaChartPie,
  FaFolder,
  FaShoppingCart,
  FaHeart,
  FaAward,
  FaBook,
  FaChalkboardTeacher,
  FaCog,
  FaBed,
  FaTools,
  FaUsersCog,
  FaUserFriends, // Alternative icon for Our Team
  FaIdBadge, // Alternative icon for Our Team
  FaHandshake,
  FaRegClock,
  FaTrophy,
} from "react-icons/fa";

// Import the logo image
import logo from "./icons/Logo.svg";

const MobileNavbar = () => {
  const [isExpanded, setExpandedState] = useState(false);

  const menuItems = [
    {
      text: "Home",

      icon: <FaHome size={24} />,
      link: "/",
    },
    {
      text: "Schedule",
      icon: <FaCalendar size={24} />,
      link: "/festschedule",
    },
    {
      text: "Ambassador",
      icon: <FaUsers size={24} />,
      link: "https://ca.sankalaniitk.in/",
    },
    {
      text: "Events",
      icon: <FaCalendarAlt size={24} />,
      link: "#events",
    },
    {
      text: "Pre-Events",
      icon: <FaRegClock size={24} />,
      link: "/pre-events",
    },
    {
      text: "Competitions",
      icon: <FaTrophy size={24} />,
      link: "/competitions",
    },
    {
      text: "Paper Presentation ",
      icon: <FaChalkboardTeacher size={24} />,
      link: "/ppc",
    },
    {
      text: "Workshops",
      icon: <FaTools size={24} />,
      link: "/workshops",
    },
    {
      text: "Accomodation",
      icon: <FaBed size={24} />,
      link: "/accommodation",
    },

    {
      text: "Sponsors",
      icon: <FaHandshake size={24} />,
      link: "/sponsors",
    },
    {
      text: "Our Team",
      icon: <FaUserFriends size={24} />,
      link: "/team",
    },
  ];
  return (
    <div className={`mobile-navbar-container ${isExpanded ? "expanded" : ""}`}>
      <button
        className={
          isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
        }
        onClick={() => setExpandedState(!isExpanded)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      {isExpanded && (
        <div className="nav-heading">
          {/* <div className="nav-brand">
            <img src={logo} alt="Logo" />
          </div> */}
        </div>
      )}

      {isExpanded && (
        <div className="mobile-menu">
          {menuItems.map(({ text, icon, link }, index) => (
            <a className="menu-item" href={link} key={index}>
              <div className="menu-item-icon" style={{ marginRight: "3px" }}>
                {icon}
              </div>
              <p>{text}</p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileNavbar;
